import { Box, Typography } from "@mui/material";
import ProductCard from "./ProductCard";
import { useLoaderData } from "@remix-run/react";
import type { loader } from "~/routes/_index";

export default function HomeProductSelection() {
  const data = useLoaderData<typeof loader>();
  const productLists = data.home.productSections;

  return productLists.map((pl, index) => (
    <div key={`list-${index}`}>
      <Typography
        variant="h4"
        sx={{ marginBottom: "1em", marginTop: "1em", textAlign: { xs: "center", md: "inherit" } }}
      >
        {pl.sectionName}
      </Typography>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {pl.products.map((p, pIndex) => (
        <Box sx={{ margin: { xs: 4, md: 2 }, width: { xs: '100%', md: '25%', minWidth: 250 } }} key={`list-product-${index}-${pIndex}`}>
        <ProductCard product={p} />
        </Box>
      ))}
      </div>
    </div>
  ));
}
