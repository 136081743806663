import { Box, Snackbar, Typography } from "@mui/material";
import type { MetaFunction, LoaderFunctionArgs } from "@remix-run/cloudflare";
import { json } from "@remix-run/cloudflare";
import { useSearchParams } from "@remix-run/react";
import { useEffect, useMemo, useState } from "react";
import CategoryCarousel from "~/components/CategoryCarousel";
import DtwImage from "~/components/DtwImage";
import Footer from "~/components/Footer";
import HomeProductSelection from "~/components/HomeProductSection";
import NavBar from "~/components/NavBar";
import TrustCarousel from "~/components/TrustCarousel";
import type { RootSchema } from "~/hooks";
import { useMainData } from "~/hooks";
import type { HomeJsonSchema, ProductJson } from "~/jsonSchemas";
import { getHomeFromSchema } from "~/schemas";

export async function loader({ context }: LoaderFunctionArgs) {
  const env = context.cloudflare.env;
  const [home, products] = await Promise.all([
    fetch(env.DTW_HOME_URL).then((r) => r.json()),
    fetch(env.DTW_PRODUCTS_URL).then((r) => r.json()),
  ]);

  const schemas = {
    home: home as HomeJsonSchema,
    products: products as ProductJson[],
  };

  const obj = {
    home: getHomeFromSchema(schemas.home, schemas.products),
  };
  return json(obj);
}

export const meta: MetaFunction = ({ matches }) => {
  const data = matches.find((m) => m.id === "root")?.data as RootSchema;
  const { main } = data;
  return [
    { title: main?.companyData.companyName ?? "DropTheWork" },
    { name: "description", content: main?.shortDescription },
  ];
};

export default function Index() {
  const { banner, categories } = useMainData();
  const [snackbarMsg, setSnackbarMsg] = useState<string>();
  const [searchParams] = useSearchParams();

  const mainCategories = useMemo(
    () => categories.filter((c) => !c.parent),
    [categories]
  );

  useEffect(() => {
    const logout = searchParams.get("logout");
    if (logout === "1") {
      setSnackbarMsg("You have been logged out.");
    }
  }, [searchParams, setSnackbarMsg]);

  const gridAlign: React.CSSProperties = {
    gridColumn: 1,
    gridRow: 1,
  };

  const closeSnack = () => setSnackbarMsg(undefined);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={3000}
        open={!!snackbarMsg}
        onClose={closeSnack}
        message={snackbarMsg}
        key="mainsnack"
      />
      <Box sx={{ display: "grid" }}>
        <Box
          sx={{ ...gridAlign, height: 384, marginTop: { xs: "4em", md: 0 } }}
        >
          <DtwImage
            sx={{
              width: 1,
              objectFit: "cover",
              height: 1,
            }}
            picture={banner.picture}
            alt={banner.alt}
          />
        </Box>

        <Box style={gridAlign}>
          <NavBar />
        </Box>
      </Box>
      <Box
        sx={(t) => ({
          marginLeft: { xs: t.spacing(2), md: t.spacing(16) },
          marginRight: { xs: t.spacing(2), md: t.spacing(16) },
          marginTop: { xs: t.spacing(4), md: t.spacing(4) },
          marginBottom: { xs: t.spacing(4), md: t.spacing(4) },
        })}
      >
        <TrustCarousel />
        {!!mainCategories && mainCategories.length > 1 && (
          <>
            <Typography variant="h4" sx={{ marginBottom: (t) => t.spacing(1) }}>
              Categories
            </Typography>
            <CategoryCarousel categories={mainCategories} />
          </>
        )}
        <HomeProductSelection />
      </Box>

      <Footer />
    </>
  );
}
